
import * as echarts from "echarts";
import { color } from "highcharts";

export const barTopsideOptions = (init, dataArr = [], sideTheme) => {
  
          //       "data": [ //数据
          //     {
          //         "mon": 0, //月份
          //         "outwsCapacity": 0.0, //出库数量 折线图
          //         "inwsCapacity": 0.0 //入库数量 柱状图
          //     }
          // ],
  // let xAxisData = [1,2,3,4,5];
  // let seriesData = [4,5,6,51,16];   
  // let lineData = [4,5,6,51,16];   

  let xAxisData = [];
  let seriesData = [];   
  let lineData = [];   
  dataArr.map(item => {
    xAxisData.push(item.mon+"月")
    seriesData.push(item.inwsCapacity)
    lineData.push(item.outwsCapacity)
  })
  init.setOption({
    grid: {
      left: '5%',
      right: '5%',
      top: '20%',
      bottom: '5%',
      containLabel: true
    },
    legend: {
      data: ['入库数量', '出库数量'],
      textStyle: {
        color: '#ccc'
      }
    },
    tooltip: {
      trigger: 'item',
      /*formatter: function (parms) {
        return parms.marker + " " + parms.name + "：" + parms.value;
      }*/
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisTick: {
        show: false
      },
      axisLine: {
        // show: false,
      },
      axisLabel: {
        fontSize: "14",
        // margin: 20,
        textStyle: {
          color: "#FFFFFF", // 修改为白色
        },
      },
      splitLine: {
        show: false,
        lineStyle: {
          // color: ["#FFFFFF"]
          color: ["rgba(126,187,255, 0.3)"],
        }
      }
    },
    yAxis: {
      type: 'value',
      axisTick: {
        show: false
      },
      splitNumber: 4,
      axisLine: {
        show: false
      },
      axisLabel: {
        show: true,
        color: '#7EBBFF',
        fontSize: 14
      },
      splitLine: {
        show: true,
        // interval: 3,
        lineStyle: {
          color: 'rgba(255,255,255,0.15)'
        }
      }
    },
    series: [
      {
        name: '入库数量',
        type: 'bar',
        barWidth: 20,
        barGap: '0%',
        itemStyle: {
          color: {
            "x": 0,
            "y": 0,
            "x2": 0,
            "y2": 1,
            "type": "linear",
            "global": false,
            "colorStops": [{
              "offset": 0,
              "color": "#00fcf7"
            }, {
              "offset": 0.5,
              // "color": "#00abff"
              "color": "rgba(0,252,247,0.3)"
            }, {
              "offset": 1,
              // "color": "#00abff"
              "color": "rgba(0,252,247,0)"
            }]
          }
        },
        data: seriesData,
      },
      {
        name: '入库数量',
        type: "pictorialBar",
        symbolSize: [18, 3],
        symbolOffset: [0, -2],
        z: 12,
        symbolPosition: "end",
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgba(255,255,255,0.8)"
            },
            {
              offset: 1,
              color: "rgba(255,255,255,0.8)"
            }
          ], false)
        },
        data: seriesData
      },
      
      {
        name: '出库数量',
        type: 'line',
        smooth: false, // 平滑曲线显示。
        // showSymbol: false, // 是否显示 symbol
        symbolSize: 6,
        showSymbol: true, // 是否显示 symbol
        itemStyle: {
          color: '#9E7F3C'
        },
        tooltip: {
          valueFormatter: function (value) {
            return value != null ? value : '' + ' °C';
          }
        },
        data: lineData,
        z: 99
      },
    ]
  }, true)
}

export const barTwosideOptions = (init, dataArr = [], sideTheme) => {
//   let xAxisData = [1,2,3,4,5];
//   let seriesData = [4,5,6,51,16];

//   "data": [ //数据
//   {
//       "mon": 0, //月份
//       "apple": 0.0, //苹果
//       "pear": 0.0, //梨
//       "walnut": 0.0, //核桃
//       "other": 0.0 //其他
//   }
// ],
let xAxisData = [];
let seriesData1 = [];   
let seriesData2 = [];   
let seriesData3 = [];   
let seriesData4 = [];
  let lineData = [];
  dataArr.map(item => {
    xAxisData.push(item.mon + "月")
    seriesData1.push(item.apple)
    seriesData2.push(item.pear)
    seriesData3.push(item.walnut)
    seriesData4.push(item.other)
    // lineData.push(item.outwsCapacity)
  })
  let legendData = ['拐枣', '拐枣醋', '拐枣饮料', '拐枣茶'];//删除了其他
  let seriesData = []

  seriesData = [

    {
      name: '拐枣',
      type: 'bar',
      barWidth: 15,
      barGap: '0%',
      itemStyle: {
        color: {
          "x": 0,
          "y": 0,
          "x2": 0,
          "y2": 1,
          "type": "linear",
            "global": false,
            "colorStops": [{
              "offset": 0,
              "color": "#00d9ff"
            },/* {
              "offset": 0.5,
              // "color": "#00abff"
              "color": "rgba(255,255,255,0.3)"
            }, {
              "offset": 1,
              // "color": "#00abff"
              "color": "rgba(0,252,247,0)"
            }*/]
          }
        },
        data: seriesData1
      },
      // {
      //   name: '苹果',
      //   type: "pictorialBar",
      //   label: {
      //     show: true,
      //     // position: ['40%', '-30'],
      //     color: '#01E4FF',
      //     fontSize: 14
      //   },
      //   // symbol: "roundRect",
      //   symbolSize: [15, 3],
      //   symbolOffset: [0, 0],
      //   itemStyle: {
      //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //       {
      //         offset: 0,
      //         color: "rgba(255,255,255,0.8)"
      //       },
      //       {
      //         offset: 1,
      //         color: "rgba(255,255,255,0.5)"
      //       }
      //     ]),
      //     shadowColor: 'rgb(255,255,255)',
      //     // shadowOffsetY: 2,
      //     // shadowBlur: 20
      //   },
      //   data: seriesData1
      // },
      // {
      //   name: '苹果',
      //   type: "pictorialBar",
      //   symbolSize: [15, 3],
      //   symbolOffset: [0, -2],
      //   z: 12,
      //   symbolPosition: "end",
      //   itemStyle: {
      //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //       {
      //         offset: 0,
      //         color: "rgba(255,255,255,0.8)"
      //       },
      //       {
      //         offset: 1,
      //         color: "rgba(255,255,255,0.8)"
      //       }
      //     ], false)
      //   },
      //   data: seriesData1
      // },
    {
      name: '拐枣醋',
      type: 'bar',
      barWidth: 15,
      barGap: '0%',
      itemStyle: {
        color: {
          "x": 0,
          "y": 0,
          "x2": 0,
          "y2": 1,
          "type": "linear",
            "global": false,
            "colorStops": [{
              "offset": 0,
              "color": "rgb(252,236,2)"
            }/*, {
              "offset": 0.5,
              // "color": "#00abff"
              "color": "rgba(39,189,169,0.3)"
            }, {
              "offset": 1,
              // "color": "#00abff"
              "color": "rgba(39,189,169,0)"
            }*/]
          }
        },
        data: seriesData2
      },
      // {
      //   name: '梨',
      //   type: "pictorialBar",
      //   label: {
      //     show: true,
      //     // position: ['40%', '-30'],
      //     color: '#01E4FF',
      //     fontSize: 14
      //   },
      //   // symbol: "roundRect",
      //   symbolSize: [15, 3],
      //   symbolOffset: [0, 0],
      //   itemStyle: {
      //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //       {
      //         offset: 0,
      //         color: "rgba(255,255,255,0.8)"
      //       },
      //       {
      //         offset: 1,
      //         color: "rgba(255,255,255,0.5)"
      //       }
      //     ]),
      //     shadowColor: 'rgb(255,255,255)',
      //     // shadowOffsetY: 2,
      //     // shadowBlur: 20
      //   },
      //   data: seriesData2
      // },
      // {
      //   name: '梨',
      //   type: "pictorialBar",
      //   symbolSize: [15, 3],
      //   symbolOffset: [0, -2],
      //   z: 12,
      //   symbolPosition: "end",
      //   itemStyle: {
      //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //       {
      //         offset: 0,
      //         color: "rgba(255,255,255,0.8)"
      //       },
      //       {
      //         offset: 1,
      //         color: "rgba(255,255,255,0.8)"
      //       }
      //     ], false)
      //   },
      //   data: seriesData2
      // },
    {
      name: '拐枣饮料',
      type: 'bar',
      barWidth: 15,
      barGap: '0%',
      itemStyle: {
        color: {
          "x": 0,
          "y": 0,
          "x2": 0,
          "y2": 1,
          "type": "linear",
            "global": false,
            "colorStops": [{
              "offset": 0,
              "color": "rgba(201,140,42,1)"
            },/* {
              "offset": 0.5,
              // "color": "#00abff"
              "color": "rgba(201,140,42,0.3)"
            }, {
              "offset": 1,
              // "color": "#00abff"
              "color": "rgba(201,140,42,0)"
            }*/]
          }
        },
        data: seriesData3
      },
      // {
      //   name: '核桃',
      //   type: "pictorialBar",
      //   label: {
      //     show: true,
      //     // position: ['40%', '-30'],
      //     color: '#01E4FF',
      //     fontSize: 14
      //   },
      //   // symbol: "roundRect",
      //   symbolSize: [15, 3],
      //   symbolOffset: [0, 0],
      //   itemStyle: {
      //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //       {
      //         offset: 0,
      //         color: "rgba(255,255,255,0.8)"
      //       },
      //       {
      //         offset: 1,
      //         color: "rgba(255,255,255,0.5)"
      //       }
      //     ]),
      //     shadowColor: 'rgb(255,255,255)',
      //     // shadowOffsetY: 2,
      //     // shadowBlur: 20
      //   },
      //   data: seriesData3
      // },
      // {
      //   name: '核桃',
      //   type: "pictorialBar",
      //   symbolSize: [15, 3],
      //   symbolOffset: [0, -2],
      //   z: 12,
      //   symbolPosition: "end",
      //   itemStyle: {
      //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //       {
      //         offset: 0,
      //         color: "rgba(255,255,255,0.8)"
      //       },
      //       {
      //         offset: 1,
      //         color: "rgba(255,255,255,0.8)"
      //       }
      //     ], false)
      //   },
      //   data: seriesData3
      // },
      /*{//注释了其他
        name: '其他',
        type: 'bar',
        barWidth: 15,
        barGap: '0%',
        itemStyle: {
          color: {
            "x": 0,
            "y": 0,
            "x2": 0,
            "y2": 1,
            "type": "linear",
            "global": false,
            "colorStops": [{
              "offset": 0,
              "color": "rgba(136,185,93,1)"
            }, {
              "offset": 0.5,
              // "color": "#00abff"
              "color": "rgba(136,185,93,0.3)"
            }, {
              "offset": 1,
              // "color": "#00abff"
              "color": "rgba(136,185,93,0)"
            }]
          }
        },
        data: seriesData4
      },*/
    {
      name: '拐枣茶',
      type: "pictorialBar",
      label: {
        show: true,
        // position: ['40%', '-30'],
        color: '#01E4FF',
        fontSize: 14
      },
      // symbol: "roundRect",
      symbolSize: [15, 3],
      symbolOffset: [0, 0],
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(255,255,255,0.8)"
          },
          {
            offset: 1,
            color: "rgba(255,255,255,0.5)"
          }
        ]),
        shadowColor: 'rgb(255,255,255)',
        // shadowOffsetY: 2,
        // shadowBlur: 20
      },
      data: seriesData4
    },
    {
      name: '其他',
      type: "pictorialBar",
      symbolSize: [15, 3],
      symbolOffset: [0, -2],
      z: 12,
      symbolPosition: "end",
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(255,255,255,0.8)"
          },
          {
            offset: 1,
            color: "rgba(255,255,255,0.8)"
          }
        ], false)
      },
      data: seriesData4
    },
  ]



  init.setOption({
    grid: {
      left: '5%',
      right: '5%',
      top: '20%',
      bottom: '5%',
      containLabel: true
    },
    legend: {
      data: legendData,
      right: 0,
      textStyle: {
        color: '#ccc'
      }
    },
    tooltip: {
      trigger: 'item',
      /*formatter: function (parms) {
        return parms.marker + " " + parms.name + "：" + parms.value;
      }*/
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisTick: {
        show: false
      },
      axisLine: {
        // show: false,
      },
      axisLabel: {
        fontSize: "14",
        // margin: 20,
        textStyle: {
          color: "#7EBBFF", // 修改为白色
        },
      },
      splitLine: {
        show: false,
        lineStyle: {
          // color: ["#FFFFFF"]
          color: ["rgba(126,187,255, 0.3)"],
        }
      }
    },
    yAxis: {
      type: 'value',
      axisTick: {
        show: false
      },
      splitNumber: 4,
      axisLine: {
        show: true
      },
      axisLabel: {
        show: true,
        color: '#ffffff',
        fontSize: 14
      },
      splitLine: {
        show: true,
        // interval: 3,
        lineStyle: {
          color: 'rgba(255,255,255,0.15)'
        }
      }
    },
    series: seriesData
  }, true)
}


export const chartOptions = (init, data, key) => {
//   "data": [ //数据
//   {
//       "days": "", //日期，格式为yyyyMMdd
//       "apple": 0.0, //苹果
//       "pear": 0.0, //梨
//       "walnut": 0.0, //核桃
//       "other": 0.0 //其他
//   }
// ],
  let xAxis = []
  let lineData = []
  data.forEach(item => {
    xAxis.push(item.days.toString().slice(2, item.days.toString().length))
    lineData.push(item[key])
  })

  init.setOption({
    grid: {
      containLabel: true,
      left: '5%',
      right: '5%',
      top: 20,
      bottom: 10,
    },

    tooltip: {
      trigger: "axis",
      valueFormatter: function (value) {
        return value;
      },
      padding: [10, 15],
      backgroundColor: "rgba(19,50,91, 0.8)",
      // backgroundColor: '#13325B',
      formatter: function (params, ticket, callback) {
        let res = params[0].name;
        for (let i = 0, l = params.length; i < l; i++) {
          res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value;
        }
        return res
      },
      textStyle: {
        color: "#FFFFFF"
      },
      borderWidth: 0
    },
    xAxis: {
      type: "category",
      boundaryGap: true,
      offset: 1,
      data: xAxis,
      axisLabel: {
        fontSize: "14",
        textStyle: {
          color: "#FFFFFF", // 修改为白色
        },
      },
      axisLine: {
        lineStyle: {
          color: "#FFFFFF", // 设置x轴颜色
        },
      },
      axisTick: {
        show: false,
      },
      // splitLine: {
      //   show: true,
      //   alignWithLabel: true,
      //   lineStyle: {
      //     // 使用深浅的间隔色
      //     color: ["rgba(126,187,255, 0.3)"],
      //   },
      //   // interval: 3
      // },
    },
    yAxis: {
      show: true,
      // type: 'value',
      // name: '单位：万户',
      splitLine: {
        show: true,
        lineStyle: {
          // 使用深浅的间隔色
          color: ["rgba(126,187,255, 0.3)"],
        },

      },
      axisLabel: {
        fontSize: "14",
        textStyle: {
          color: "#FFFFFF", // 修改为白色
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: "rgba(255,255,255, 0)", // 设置y轴颜色
        },
      },
      splitNumber: 6
    },
    series: [
      {
        data: lineData,
        name: xAxis[0],
        type: "line",
        smooth: false, // 平滑曲线显示。
        // showSymbol: false, // 是否显示 symbol
        symbolSize: 6,
        showSymbol: true, // 是否显示 symbol
        itemStyle: {
          color: '#2FA080'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(
                0,0,0,1,
                [
                  {
                    offset: 0,
                    color: "rgba(0, 174, 255, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0, 174, 255, 0.02)",
                  },
                ],false
              ),
        }
      }
    ],
  }, true)
}